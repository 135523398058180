import ImageToTextIcon from '../assets/icons/image_file.svg';
import PdftoWordConvertorIcon from '../assets/icons/pdf.svg';
import WordtoPdfConvertorIcon from '../assets/icons/microsoft_word.svg';
import ConverttoPngIcon from '../assets/icons/png.svg';
import {createContext} from 'react';

export const DocConversationContext = createContext();

const DocConversationState = (props) => {
    const DocumentConversionTools = [
        {
          icon: ImageToTextIcon,
          alt: 'Effortlessly use our Image to text ai converter Online Toolstrain.com',
          focusheading: 'Image To Text AI Converter',
          heading: 'Effortlessly use our Image to text ai converter Online.',
          description: 'Use free image to text ai converter online. Best fast and free online image OCR tool. Now, extract your images to text simply with 1 click - Toolstrain.',
          ToPath: '/image-to-text-convertor',
        },
        {
          icon: PdftoWordConvertorIcon,
          alt: 'Best Free Pdf to Word Converter - Fast & Accurate Toolstrain.com',
          focusheading: 'Pdf to Word Converter',
          heading: 'Best Free Pdf to Word Converter - Fast & Accurate.',
          description: 'Best Free PDF to Word Converter just in a seconds! Get you PDF file into DOCX and the best part is you can edit is well - Toolstrain.',
          ToPath: '/pdf-to-word-convertor',
        },
        {
          icon: WordtoPdfConvertorIcon,
          alt: 'Word To Pdf Converter Online Free - Free Tool Toolstrain.com',
          focusheading: 'Word To Pdf Converter',
          heading: 'Word To Pdf Converter Online Free - Free Tool.',
          description: 'Lookin for best Word to PDF Converter Online Free? Don’t Worry Toolstrain has got a free document converter just for you!.',
          ToPath: '/word-to-pdf-convertor',

        },
        {
          icon: ConverttoPngIcon,
          alt: 'Best Free Tool to Convert Image To Png Easily Toolstrain.com',
          focusheading: 'Convert Image To Png Easily',
          heading: 'Best Free Tool to Convert Image To Png Easily',
          description: 'Hassle free Convert image to Png by using Toolstrain free image converter with no signup or Credentials required!.',
          ToPath: '/convert-into-png',
        },
        {
          icon: ConverttoPngIcon,
          alt: 'Free Image Compressor Online - Without Signup Toolstrain.com',
          focusheading: 'Image Compressor Online',
          heading: 'Free Image Compressor Online - Without Signup',
          description: 'Free image compressor online - compress your images without the hassle of signing up. Reduce file sizes effortlessly and optimize website performance.',
          ToPath: '/image-file-compressor',
        },
    ];
    return (
        <DocConversationContext.Provider value={DocumentConversionTools}>
            {props.children}
        </DocConversationContext.Provider>
    )
}
export default DocConversationState;