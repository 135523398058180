
import URL from '../assets/icons/website_url.svg';
import BioProfile from '../assets/icons/fingerprint.svg';
import QRCode from '../assets/icons/qr_code.svg';
import {createContext} from 'react';

export const WebServicesContext = createContext();
const WebServicesContextState = (props) => {
    const WebServicesTools = [
        {
          icon: URL,
          alt: 'Boost Visibility With Smart Url Shortener Free Online. Toolstrain.com',
          focusheading: 'Url Shortener Free Online',
          heading: 'Boost Visibility With Smart Url Shortener Free Online',
          description: 'Boost your online visibility with our smart URL shortener free online . Create concise and shareable links to drive traffic and improve click-through rates.',
          toPath:'/',
        },
        {
          icon: BioProfile,
          alt: 'Optimize Your Bio Links for Success Toolstrain.com',
          focusheading: 'Bio Link For Social Media',
          heading: 'Optimize Your Bio Links for Success',
          description: 'Optimize your bio links for success and maximize your online presence. Drive more traffic and engagement by creating effective and strategic bio links.',
          toPath:'/bio',
        },
        {
          icon: QRCode,
          alt: 'Qr Code Generator Toolstrain.com',
          focusheading: 'Advance Free QR Code Generator',
          heading: 'Free QR Code Generator - Create QR Code for URL | No Sign Up',
          description: 'Generate QR codes for URLs with our free and user-friendly QR code generator. No sign-up required. Create, customize, and share QR codes easily.',
          toPath:'/qr-code-generator',
        },
    ];
    return (
        <WebServicesContext.Provider value={WebServicesTools}>
            {props.children}
        </WebServicesContext.Provider>
    )
}
export default WebServicesContextState;