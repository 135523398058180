import React from 'react'
import Statistic from '../assets/icons/account_usage.svg'
import DataQuality from '../assets/icons/data_quality.svg'
import Watermark from '../assets/icons/copyright.svg'

const WhyUs = () => {
  return (
    <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1 z-0'>
        <div className='grid grid-cols-1 mx-0 sm:mx-[5%] pt-8'>
           <div className='bg-Primary rounded-none sm:rounded-lg p-2 py-4 sm:px-3 sm:py-5 grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 md:px-5 md:py-6'>
                <div className="grid grid-cols-1 border-2 border-Secoundaryv1 rounded-xl px-4 py-5 bg-White">
                    <img src={Statistic} alt="Statistics Toolstrain.com" className="w-12 h-auto object-cover rounded-md" />
                    <h3 className='grid grid-cols-1 py-2'>Unlimited Usage</h3>
                    <h4 className='grid grid-cols-1 pb-2'>Use as much as you need. No limit or any other restriction.</h4>
                </div>
                <div className="grid grid-cols-1 border-2 border-Secoundaryv1 rounded-xl px-4 py-5 bg-White">
                    <img src={DataQuality} alt="Statistics Toolstrain.com" className="w-12 h-auto object-cover rounded-md" />
                    <h3 className='grid grid-cols-1 py-2'>High Quality</h3>
                    <h4 className='grid grid-cols-1 pb-2'>Our Tool will provide accurate and high quality results.</h4>
                </div>
                <div className="grid grid-cols-1 border-2 border-Secoundaryv1 rounded-xl px-4 py-5 bg-White sm:col-span-full md:col-span-1">
                    <img src={Watermark} alt="Statistics Toolstrain.com" className="w-12 h-auto object-cover rounded-md" />
                    <h3 className='grid grid-cols-1 py-2'>No Watermark</h3>
                    <h4 className='grid grid-cols-1 pb-2'>Use all tools without watermark logo.</h4>
                </div>
           </div>
        </div>
    </div>
  )
}

export default WhyUs
