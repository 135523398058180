import React, { useEffect } from 'react'
import WordtoPdf from '../sections/Tool Section/WordtoPdf'
import CategorySliderFrame from '../sections/CategorySliderFrame'
import CategorySliderFrame1 from '../sections/CategorySliderFrame1'
import WhyUs from '../sections/WhyUs'
import { Helmet } from 'react-helmet';
import CategorySliderFrame3 from '../sections/CategorySliderFrame3'

const WordToPdfConvertor = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Lookin for best Word to PDF Converter Online Free? Don’t Worry Toolstrain has got a free document converter just for you! | Tools Train.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Looking for best Word to PDF Converter Online Free? Don’t Worry Toolstrain has got a free document converter just for you!" />
        <meta name="keywords" content="Word to PDF converter, convert Word to PDF, create PDF from Word, document to PDF conversion, PDF generator" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
        <div className='bg-Transparent1'>
          <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
              <div className=' grid grid-cols-1 mx-[5%] py-[25%] bg-hero-pattern bg-no-repeat bg-center bg-cover opacity-95'>
                <WordtoPdf />
              </div>
          </div>
        </div>
        <CategorySliderFrame />
        <CategorySliderFrame1 />  
        <CategorySliderFrame3 />
        <WhyUs />
    </React.Fragment>
  )
}

export default WordToPdfConvertor
