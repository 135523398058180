
import URL from '../assets/icons/website_url.svg';
import  BioProfile from '../assets/icons/fingerprint.svg';
import {createContext} from 'react';

export const ProductivityToolsContext = createContext();
const ProductivityToolsContextState = (props) => {
    const ProductivityTools = [
        {
          icon: URL,
          alt: 'Age Calculator By Date Of Birth: Unlock Your Life Story Toolstrain.com',
          focusheading: 'Age Calculator',
          heading: 'Age Calculator By Date Of Birth: Unlock Your Life Story',
          description: 'Unlock your life story with our age calculator by date of birth. Discover your age and milestones effortlessly.',
          toPath:'/age-calculator',
        },
        {
          icon: BioProfile,
          alt: 'YouTube Thumbnail Downloader Online: Grab Attention with Clickworthy Images Toolstrain.com',
          focusheading: 'YouTube Thumbnail Downloader Online',
          heading: 'YouTube Thumbnail Downloader Online: Grab Attention with Clickworthy Images',
          description: 'Grab attention with clickworthy images using our YouTube thumbnail downloader online. Enhance your videos visibility..',
          toPath:'/youtube-thumbnail-downloader',
        },
        {
          icon: BioProfile,
          alt: 'Strong Random Lastpass Password Generator: Safeguard Your Digital World Toolstrain.com',
          focusheading: 'Random Lastpass Password Generator',
          heading: 'Strong Random Lastpass Password Generator: Safeguard Your Digital World',
          description: 'Safeguard your digital world with a strong random Lastpass password generator. Protect your accounts effectively.',
          toPath:'/password-genrator',
        }, 
        {
          icon: BioProfile,
          alt: 'Online Free Invoice Generator: Manage Finances Seamlessly Toolstrain.com',
          focusheading: 'Free Invoice Generator',
          heading: 'Online Free Invoice Generator: Manage Finances Seamlessly',
          description: 'Manage your finances seamlessly with our online free invoice generator. Create professional invoices hassle-free.',
          toPath:'/invoice-genrator',
        },       
        {
          icon: BioProfile,
          alt: 'Free Hash Tag Generator: Boost Your Social Posts Toolstrain.com',
          focusheading: 'Free Hash Tag Generator',
          heading: 'Free Hash Tag Generator: Boost Your Social Posts',
          description: 'Boost your social posts with our free hashtag generator. Enhance your reach and engagement on social media.',
          toPath:'/hash-Tag-genrator',
        },        
        {
          icon: BioProfile,
          alt: 'Online Encoder and Decoder: Break the Language Barrier Toolstrain.com',
          focusheading: 'Online Encoder & Decoder',
          heading: 'Online Encoder and Decoder: Break the Language Barrier',
          description: 'Break the language barrier with our online encoder and decoder. Communicate effectively in different languages.',
          toPath:'/encoder-decoder',
        },
        {
          icon: BioProfile,
          alt: 'Pdf Word Counter Tool Online: Keep Track of Your Writing Progress Toolstrain.com',
          focusheading: 'Pdf Word Counter Tool',
          heading: 'Pdf Word Counter Tool Online: Keep Track of Your Writing Progress',
          description: 'Keep track of your writing progress with our PDF Word counter tool online. Monitor word count and productivity effortlessly.',
          toPath:'/words-counter',
        },
    ];
    return (
        <ProductivityToolsContext.Provider value={ProductivityTools}>
            {props.children}
        </ProductivityToolsContext.Provider>
    )
}
export default ProductivityToolsContextState;