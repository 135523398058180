import React, { useEffect } from 'react'
import HomeBannerFrame from '../sections/HomeBannerFrame'
import CategorySliderFrame from '../sections/CategorySliderFrame'
import CategorySliderFrame1 from '../sections/CategorySliderFrame1'
import WhyUs from '../sections/WhyUs'
import { Helmet } from 'react-helmet';
// import CategorySliderFrame2 from '../sections/CategorySliderFrame2'
import CategorySliderFrame3 from '../sections/CategorySliderFrame3'
import UrlShortenImg from '../assets/images/Easy to Use Free URL Shortener.png'

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
   // Define the JSON data outside of the JSX code
  const schemaData = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "Toolstrain",
    "url": "https://toolstrain.com/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://toolstrain.com/{search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };
  return (
    <React.Fragment>
       <Helmet>
        <title>Free URL Shortener - 14+ Free Online Web Tools by Toolstrain.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Use Toolstrain Free Online Web tools like, Free URL Shortener, Image to Text Converter, Hashtag Generator, Youtube thumbnail download, and more!" />
        <meta name="keywords" content="tools train, web services, text assistance, tiny url, link shortener" />
        <link rel="canonical" href={window.location.href} />
        {/* #S */}
        <meta property="og:title" content="Most Convenient and Easy to Use Free URL Shortener"/>
        <meta property="og:site_name" content="Toolstrain"/>
        <meta property="og:url" content="https://toolstrain.com" />
        <meta property="og:description" content="Get Free TinyURL shortener, You don’t have to pay a single penny for creating short urls.
        Now, Creating short & memorable links is seconds with our Free URL Shortener for converting lengthy links into good looking, Identifiable and remarkable short URLs." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={UrlShortenImg}/>
        <meta name="twitter:card" content="product" />
        <meta name="twitter:site" content="@toolstrain" />
        <meta name="twitter:description" content="Get Free TinyURL shortener, You don’t have to pay a single penny for creating short urls.
        Now, Creating short & memorable links is seconds with our Free URL Shortener for converting lengthy links" />
        <meta name="twitter:app:name:iphone" content="url shortener" />
        <meta name="twitter:app:id:iphone" content="" />
        <meta name="twitter:app:name:ipad" content="url shortener" />
        <meta name="twitter:app:id:ipad" content="" />
        <meta name="twitter:app:name:googleplay" content="url shortener" />
        <meta name="twitter:app:id:googleplay" content="" />
        <meta name="twitter:app:country" content="" />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <HomeBannerFrame/>
      <CategorySliderFrame />
      <CategorySliderFrame1 />
      {/* <CategorySliderFrame2 /> */}
      <CategorySliderFrame3 />
      <WhyUs />
    </React.Fragment>
  )
}

export default Home