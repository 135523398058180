import React from 'react'
import UrlShortner from './Tool Section/UrlShortner'

const HomeBannerFrame = () => {
  return (
    <React.Fragment>
        <div className='bg-Transparent1'>
          <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
              <div className=' grid grid-cols-1 mx-[5%] pt-[10%] bg-hero-pattern bg-no-repeat bg-center bg-cover opacity-[0.85]'>
                 <UrlShortner />
                 <div className='grid grid-cols-1 py-[2%] mt-4 pb-[7%] items-center justify-items-center gap-5 sm:gap-2'>
                    <div className='grid grid-cols-1 gap-2 max-w-full sm:max-w-3xl'>
                      <p className='text-center text-sm sm:text-lg'>For converting lengthy links into good looking, Identifiable and remarkable short URLs.</p>
                      <p className='text-sm sm:text-lg text-justify'>Get Free TinyURL shortener, You don’t have to pay a single penny for creating short urls.
                      Now, Creating short & memorable links is seconds with our Free URL Shortener for converting lengthy links into good looking, Identifiable and remarkable short URLs.</p>
                      <p className='text-sm sm:text-lg text-justify'>Our Url shortener is compatible for any platform including; Websites, Social Media Platforms, Mails, SMS, Academic link shortener, Our Toolstrain free url shortener is the perfect alternative to bitly url shortener, or t.ly short url websites.</p>
                    </div>
                 </div>
              </div>
          </div>
        </div>
    </React.Fragment>
  )
}

export default HomeBannerFrame;
